import { template as template_cb5c539cd8d647399296ca0484a5ce93 } from "@ember/template-compiler";
const WelcomeHeader = template_cb5c539cd8d647399296ca0484a5ce93(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
