import { template as template_56f9d1c8c8e54e55bdf8283212e0fb3d } from "@ember/template-compiler";
const FKText = template_56f9d1c8c8e54e55bdf8283212e0fb3d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
